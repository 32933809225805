/**
 * Connect the old chase api events via the Columbo API
 */
define(['cookie', 'columboConstants', '$console', 'siteObj', '$window'], function(cookie, columboConstants, $console, siteObj, $window) {
  const module = {};
  const sessionId = cookie.get(columboConstants.chaseSessionKey);
  const userId = cookie.get(columboConstants.chaseUserKey);

  const _sendFrontEndEvent = function(path, name, message) {
    if (siteObj.debugFlag) {
      $console.log('%cColumbo BigQuery Connector Event', 'background: #333; color: #aaffcc', arguments);
    }
    const script = document.createElement('script');

    // Optional message, remove multiple space + tab
    if (message) {
      message = message.replace(/(%20)+/g, '%20').replace(/(%09)+/g, '%09');
      path += '?eventMessage=' + encodeURIComponent(message);
      path += '&component=default';
    }

    // Append script
    script.async = true;
    script.src = path;
    document.getElementsByTagName('head')[0].appendChild(script);

    return path;
  };

  const _send = function(path, name, message) {
    if (siteObj.debugFlag) {
      $console.log('%cColumbo BigQuery Connector Event', 'background: #333; color: #aaffcc', arguments);
    }
    const timestamp = new Date().getTime();
    const script = document.createElement('script');

    // Build query params
    path = module.addQueryStringParamToURL(path, 'i', timestamp + '_' + columboConstants.columboVersion);
    path = module.addQueryStringParamToURL(path, 'ref', document.referrer);

    // Optional session, user info and event name
    path = sessionId ? module.addQueryStringParamToURL(path, 'sessionId', sessionId) : path;
    path = userId ? module.addQueryStringParamToURL(path, 'user', userId) : path;
    path = name ? module.addQueryStringParamToURL(path, 'event', name) : path;

    // Optional message, remove multiple space + tab
    if (message) {
      message = message.replace(/(%20)+/g, '%20').replace(/(%09)+/g, '%09');
      path += '&msg=' + encodeURIComponent(message);
    }

    // Append script
    script.async = true;
    script.src = path;
    document.getElementsByTagName('head')[0].appendChild(script);

    return path;
  };

  const _sendPerfValues = function(path, body) {
    const timestamp = new Date().getTime();

    path = module.addQueryStringParamToURL(path, 'i', timestamp + '_' + columboConstants.columboVersion);

    if (navigator.sendBeacon) {
      navigator.sendBeacon(path, body);
    } else if ('fetch' in $window) {
      fetch(path, {body, method: 'POST', keepalive: true}).catch(() => {});
    }
  };

  const _addQueryStringParamToURL = function(url, paramName, paramValue) {
    if (!paramName) {
      return url;
    }

    paramName = encodeURIComponent(paramName);
    paramValue = encodeURIComponent(paramValue);

    // matches the url using regex
    // group 1 is everything until ? (where queries start)
    // group 2 is the query
    // group 3 is the rest of the url
    const match = url.match(/^([^?#]*)\?{0,1}([^#]*)(.*)$/);

    // only change if it's matching and not already in url
    if (match.length === 4 && url.indexOf(paramName + '=') === -1) {
      const base = match[1];
      const query = match[2].length ? '?' + match[2] + '&' : '?';
      const hash = match[3];
      return base + query + paramName + '=' + paramValue + hash;
    }

    return url;
  };

  /**
   * Push event to Google Tag Manager
   * @param {object} object - Object sent to Google Tag Manager
   * @returns {boolean}
   */
  const _gtmEventTrack = function(object) {
    if (siteObj.debugFlag) {
      $console.log('%cGTM Connector Event', 'background: #333; color: #ffaacc', object);
    }
    if (object && typeof object === 'object' && object.constructor === Object) {
      window.dataLayer.push(object);
      return true;
    }
    return false;
  };

  module.sendFrontEndEvent = _sendFrontEndEvent;
  module.send = _send;
  module.sendPerfValues = _sendPerfValues;
  module.addQueryStringParamToURL = _addQueryStringParamToURL;
  module.gtmEventTrack = _gtmEventTrack;

  return module;
});
